import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FaUserAlt } from "react-icons/fa";
import "./Home.css";
import apiInstance from "../../api";
import NumberFormat from "react-number-format";
import { useNavigate } from "react-router-dom";
import { SochcastListensMonthly } from "../reports/SochcastMonthlyListens";
import { Listens } from "../reports/Listens";


export const HomePageCards = (props) => {
  // All State Data
  const [dashboardData, setDashboardData] = useState(null);
  // History Navigation
  const navigate = useNavigate();

  // Get Today Date
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();

  today = yyyy + "-" + mm + "-" + dd;

  // Get first Date of current month
  var my_date = new Date();
  var first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 2);
  var first_day_current_month = first_date.toISOString().split("T")[0];

  const handleDetailedReport = () => {
    navigate("/monthly-listeners");
  };

  const handleTotalListens = () => {
    navigate("/total-monthly-listens");
  };

  const handleSignups = () => {
    navigate("/total-signups");
  };

  const handleShows = () => {
    navigate("/shows");
  };

  const handleEpisodes = () => {
    navigate("/episodes");
  };

  const handleContents = () => {
    navigate("/creators");
  };

  const { selectedDateRange } = props;
  useEffect(() => {
    if (selectedDateRange) {
      apiInstance
        .get("/dashboard-data", {
          params: {
            days: selectedDateRange
          },
        })
        .then((res) => {
          if (res.status === 200) {
            console.log("res: ", res);

            setDashboardData(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      apiInstance
        .get("/dashboard-data")
        .then((res) => {
          if (res.status === 200) {
            console.log("res: ", res);

            setDashboardData(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedDateRange]);

  useEffect(() => {
    apiInstance
      .get("/dashboard-data")
      .then((res) => {
        if (res.status === 200) {
          console.log("res: ", res);
          setDashboardData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Container className="px-3">
      <>
        <p>Sochcast Platform Data</p>
        <Row>
          {/* Total Listeners */}
          <Col
            xs={12}
            sm={3}
            md={3}
            lg={3}
            className="mb-4"
            onClick={handleDetailedReport}
          >
            <Card className="metrics shadow py-2">
              <Card.Body>
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-info text-uppercase mb-1 ">
                      Unique listeners{" "}
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      <NumberFormat
                        value={
                          selectedDateRange
                            ? dashboardData?.unique_listener_daterange
                            : dashboardData?.total_unique_listener
                        }
                        className="foo"
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandsGroupStyle="lakh"
                      />
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

         

          {/* total signups */}
          <Col
            xs={12}
            sm={2}
            md={2}
            lg={2}
            className="mb-4"
            onClick={handleSignups}
          >
            <Card className="shadow py-2">
              <Card.Body>
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-range text-uppercase mb-1">
                      Signups
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      <NumberFormat
                        value={
                          selectedDateRange
                            ? dashboardData?.total_signups_daterange
                            : dashboardData?.total_signups
                        }
                        className="foo"
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandsGroupStyle="lakh"
                      />
                    </div>
                  </div>
                  <div className="col-auto">
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* total shows */}
          <Col
            xs={12}
            sm={2}
            md={2}
            lg={2}
            className="mb-4"
            onClick={handleShows}
          >
            <Card className="shadow py-2">
              <Card.Body>
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                      Shows
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      <NumberFormat
                        value={
                          selectedDateRange
                            ? dashboardData?.total_shows_daterange
                            : dashboardData?.total_shows
                        }
                        className="foo"
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandsGroupStyle="lakh"
                      />
                    </div>
                  </div>
                  <div className="col-auto">
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col> 

          {/* total episodes */}
          <Col
            xs={12}
            sm={2}
            md={2}
            lg={2}
            className="mb-4"
            onClick={handleEpisodes}
          >
            <Card className="shadow py-2">
              <Card.Body>
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-range text-uppercase mb-1">
                      Episodes
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      <NumberFormat
                        value={
                          selectedDateRange
                            ? dashboardData?.total_episodes_daterange
                            : dashboardData?.total_episodes
                        }
                        className="foo"
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandsGroupStyle="lakh"
                      />
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* Sochcast originals */}
          <Col xs={12} sm={3} md={3} lg={3} className="mb-4">
            <Card className="shadow py-2">
              <Card.Body>
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                      Sochcast Originals
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      <NumberFormat
                        value={
                          selectedDateRange
                            ? dashboardData?.sochcast_originals_daterange
                            : dashboardData?.sochcast_originals
                        }
                        className="foo"
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandsGroupStyle="lakh"
                      />
                    </div>
                  </div>
                  <div className="col-auto">
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={12} md={12} lg={10} className="mb-4">
          <Card className="shadow">
            <Card.Body>
            <SochcastListensMonthly />
            </Card.Body>
          </Card>
          </Col>
          <Col xs={12} sm={4} md={4} lg={2} className="mb-4">
              <Col lg={12}>
              <Card className="shadow">
            <Card.Body>
            <Listens />
            </Card.Body>
          </Card>
              
              </Col>
          </Col>

          <Col>
          <Row>
            {/* TOTAL MINUTES OF CONTENT CREATED */}
          <Col
            xs={12}
            sm={6}
            md={6}
            lg={3}
            className="mb-4"
            onClick={handleContents}
          >
            <Card className="shadow py-2">
              <Card.Body>
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                      CONTENT CREATED
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      <NumberFormat
                        value={Math.round(
                          (selectedDateRange
                            ? dashboardData?.total_content_based_on_daterange
                                .duration__sum
                            : dashboardData?.total_content.duration__sum) / 60
                        )}
                        className="foo"
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandsGroupStyle="lakh"
                      />{" "}
                      minutes
                    </div>
                  </div>
                  <div className="col-auto">
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* TOTAL MINUTES OF CONTENT CONSUMED */}
          <Col
            xs={12}
            sm={6}
            md={6}
            lg={3}
            className="mb-4"
            onClick={handleContents}
          >
            <Card className="shadow py-2">
              <Card.Body>
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-range text-uppercase mb-1">
                      CONTENT CONSUMED
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      <NumberFormat
                        value={Math.round(
                          (selectedDateRange
                            ? dashboardData?.total_content_consumed_daterange
                            : dashboardData?.total_content_consumed) / 60
                        )}
                        className="foo"
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandsGroupStyle="lakh"
                      />{" "}
                      minutes
                    </div>
                  </div>
                  <div className="col-auto">
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* Branded content */}
          <Col xs={12} sm={6} md={6} lg={3} className="mb-4">
            <Card className="shadow py-2">
              <Card.Body>
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                      Branded content
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      <NumberFormat
                        value={Math.round(
                          (selectedDateRange
                            ? dashboardData?.branded_content_duration_daterange
                                .duration__sum
                            : dashboardData?.branded_content_duration
                                .duration__sum) / 60
                        )}
                        className="foo"
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandsGroupStyle="lakh"
                      />{" "}
                      minutes
                    </div>
                  </div>
                  <div className="col-auto">
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>


          {/* SHOWS DISTRIBUTED /W SOCHCAST */}
          <Col xs={12} sm={6} md={6} lg={3} className="mb-4">
            <Card className="shadow py-2">
              <Card.Body>
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-range text-uppercase mb-1">
                      SHOWS DISTRIBUTED /W SOCHCAST
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      <NumberFormat
                        value={
                          selectedDateRange
                            ? dashboardData?.distributed_shows_daterange
                            : dashboardData?.total_distributed_shows
                        }
                        className="foo"
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandsGroupStyle="lakh"
                      />
                    </div>
                  </div>
                  <div className="col-auto">
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          </Row>
          </Col>
          
        </Row>
        
        <p>Total Data From All The Platforms</p>
        <Row>
          <Col xs={12} sm={6} md={6} lg={6} className="mb-4">
            <Card className="shadow py-2">
              <Card.Body>
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                      Total Listens
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      14,48,200 Listens
                    </div>
                  </div>
                  <div className="col-auto">
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6} className="mb-4">
            <Card className="shadow py-2">
              <Card.Body>
                <div className="row no-gutters align-items-center">
                  <div className="col mr-2">
                    <div className="text-xs font-weight-bold text-range text-uppercase mb-1">
                      Total Listeners
                    </div>
                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                      6,81,728
                    </div>
                  </div>
                  <div className="col-auto">
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* <Tooltip id="showName" place="top" effect="solid">
        Pick a great name for your show
      </Tooltip> */}
      </>
    </Container>
  );
};
