import React, { useState, useEffect } from "react";
// import { GoogleSignIn } from "./form/GoogleSignIn";
import apiInstance from "../../api";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { Button, Row, Col } from "react-bootstrap";
import * as Yup from "yup";
import { InputField } from "./form/InputField";
import { useNavigate } from "react-router";
// import { useCookies } from "react-cookie";

export const SigninForm = () => {
  const [errorMsg, seterrorMsg] = useState("");
  const [me, setMe] = useState(false);
  const navigate = useNavigate();
  // const [cookies, setCookie] = useCookies(["user"]);

//   useEffect(() => {
//     let auth = localStorage.getItem("isAuthenticated");
//     if (auth) {
//       navigate.push("/home");
//     } else {
//       navigate.push("/signin");
//     }
//   }, []);

  const initialValues = {
    email: "",
    password: "",
    rememberMe: false,
  };

  const validate = Yup.object({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
  });

  // const handleRememberMe = (cemail, cpassword) => {
  //   setCookie("Email", cemail, { path: "/" });
  //   setCookie("Password", cpassword, { path: "/" });
  // };

  const handleLoginSubmit = async (values) => {
    // if (values.rememberMe) {
    //   handleRememberMe(values.email, values.password);
    // }
    await apiInstance
      .post(`https://backend.sochcast.com/api/v1/login/`, {
        email: values.email,
        password: values.password,
      })
      .then((response) => {
        if (response.status === 200 /*&& response.data.status === 404*/) {
          // if (response.data.errors[0].message[0] === "User not found") {
          //   seterrorMsg(response.data.errors[0].message[0]);
          // }
          localStorage.setItem("isAuthenticated", "true");
          localStorage.setItem("access", response.data.payload.access);
          localStorage.setItem("refresh", response.data.payload.refresh);
          localStorage.setItem("user_id", response.data.payload.user_id);
          localStorage.setItem("first_name", response.data.payload.first_name);
          localStorage.setItem("last_name", response.data.payload.last_name);
          navigate("/home");
        } else if (response.status === 403) {
          seterrorMsg(response.data.errors[0].message[0]);
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          seterrorMsg(error.response.data.errors[0].message);
        } else if (error.response.status === 404) {
          seterrorMsg(error.response.data.errors[0].message);
        }
      });
  };



  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validate}
        onSubmit={handleLoginSubmit}
      >
        {(formik) => (
          <div>
            <h3 className="login-text-page">Login</h3>
            <p className="sub-text-login-page">
              Sochcast is a premier full-stack audio suite that enables you to
              broadcast your सोच . <span>(soch)</span> thoughts & musings.
            </p>
            <Form>
              <Row className="mt-5">
                <Col xs={12} md={12} lg={12} xl={12}>
                  <InputField
                    label="Email"
                    name="email"
                    type="email"
                    data-testid="signin-email"
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={12} lg={12} xl={12}>
                  <InputField
                    label="Password"
                    name="password"
                    type="password"
                    data-testid="signin-password"
                  />
                </Col>
              </Row>

              {/* <Row>
                <Col xs={12} md={6} lg={6} xl={6} className="text-left">
                  <label htmlFor="rememberMe">
                    <Field
                      type="checkbox"
                      name="rememberMe"
                      onChange={formik.handleChange}
                      defaultChecked={me}
                      id="rememberMe"
                    />{" "}
                    Remember me
                  </label>
                </Col>
                <Col
                  xs={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="text-right forgot-password"
                >
                  <Link to="/forgot-password">Forget password ?</Link>
                </Col>
              </Row> */}

              <p className="mt-2 error text-center">{errorMsg}</p>

              <Row className="mt-4">
                <Col xs={12} md={12} lg={12} xl={12} className="text-center">
                  <Button
                    variant="primary"
                    type="submit"
                    className="sigInButton rounded-2 buttonColor"
                  >
                    Sign In
                  </Button>
                </Col>
              </Row>
            </Form>

            {/* <h1 className="mt-4 horizontalLineSiginIn">Sign In with</h1> */}

            {/* <Row className="mt-2">
              <p className="text-center login">
                Don't have an Account ? <BrowserRouter>
                <Link to="/signup" className="signinLink">
                  Signup here
                </Link>
                </BrowserRouter>
              </p>
            </Row> */}

            {/* <GoogleSignIn /> */}
          </div>
        )}
      </Formik>
    </>
  );
};
