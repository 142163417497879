import React from "react";
import { Row, Col, Container } from "react-bootstrap";
// import "../css/signin/signin.css";
import { SigninForm } from "./SigninForm";
import sochLogo from "../../assets/images/sochlogo.png";

export const Signin = () => {
  return (
    <Container>
      <Row>
        <Col md={3} className="signin-left"></Col>
        <Col className="mt-5 p-3" md={6}>
          <div className="sochlogo text-center">
            <img src={sochLogo} alt="SochCast Logo" />
          </div>
          <SigninForm />
        </Col>
      </Row>
    </Container>
  );
};
