import React, { useState } from "react";
import { HomeReportsFilter } from "./HomeReportsFilter";
import { HomePageCards } from "./HomePageCards";
import { TopNavigation } from "../../components/sidebar/TopNavigation";

export const Home = () => {
  const [selectedDateRange,setSelectedDateRange]=useState(null);
  return (
    <>
      <TopNavigation />
      <HomeReportsFilter setSelectedDateRange={setSelectedDateRange} selectedDateRange={selectedDateRange}/>
      <HomePageCards selectedDateRange={selectedDateRange}/>
    </>
  );
};
