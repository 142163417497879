import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { TopNavigation } from "../../components/sidebar/TopNavigation";
import Select from "react-select";
import { ContentCreated } from "../reports/ContentCreated";
import { ContentConsumed } from "../reports/ContentConsumed";
import apiInstance from "../../api";
import moment from "moment";

export const Creators = () => {
  const [selectDateRange, setSelectDateRange] = useState(500);
  const [creatorData, setCreatorData] = useState(null);

  const creatorList = () => {
    apiInstance
      .get(`/top-shows-daterange?days=${selectDateRange}`)
      .then((res) => {
        if (res.status === 200) {
          setCreatorData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    creatorList();
  }, [selectDateRange]);

  const options = [
    { value: 500, label: "All Time" },
    { value: 7, label: "7 Days" },
    { value: 15, label: "15 Days" },
    { value: 30, label: "30 Days" },
    { value: 45, label: "45 Days" },
    { value: 60, label: "60 Days" },
    { value: 90, label: "90 Days" },
    { value: 120, label: "120 Days" },
    { value: 180, label: "180 Days" },
    { value: 365, label: "1 Year" },
  ];

  return (
    <>
      <TopNavigation />
      <Container>
        <div className="my-4">
          <h3 className="text-capitalize">top Creators</h3>
          <Row className="my-2">
            <Col xs={12} sm={12} lg={4} md={4}>
              <Select
                defaultValue={{ label: "All Time", value: 500 }}
                onChange={(e) => setSelectDateRange(e.value)}
                options={options}
                isSearchable={false}
                isRtl={false}
              />
            </Col>
          </Row>
          <Row>
            {creatorData?.map((e) => (
              <Col
                className="mb-3 d-flex justify-content-center"
                xs={6}
                md={4}
                lg={2}
              >
                <Card className="">
                  <Card.Body>
                    <Row>
                      <Col>
                        <img
                          src={e.show_image}
                          alt="trunk tales"
                          className="img-fluid show-image-top-shows"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <p>{e.hosts.first_name}</p>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>

        <div className="mb-5">
          <Row>
            <ContentCreated />
          </Row>
          <Row>
            <ContentConsumed />
          </Row>
        </div>
      </Container>
    </>
  );
};
