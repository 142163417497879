import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "./pages/home/Home";
import { Episodes } from "./pages/episodes/Episodes";
import { Show } from "./pages/shows/Show";
import { Creators } from "./pages/creators/Creators";
import { MonthlyListeners } from "./pages/reports/MonthlyListeners";
import { ContentCreated } from "./pages/reports/ContentCreated";
import { SochcastListensMonthly } from "./pages/reports/SochcastMonthlyListens";
import { TotalSignups } from "./pages/reports/TotalSignups";
import { TotalShows } from "./pages/reports/TotalShows";
import { TotalEpisodes } from "./pages/reports/TotalEpisodes";
import { ContentConsumed } from "./pages/reports/ContentConsumed";
import { Signin } from "./pages/login/Signin";
import ProtectedRoute from "./pages/login/ProtectedRoute";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route path="/home" element={<ProtectedRoute component={Home} />} />
        <Route path="/episodes" element={<ProtectedRoute component={Episodes}/>} />
        <Route path="/shows" element={<ProtectedRoute component={Show} />} />
        <Route path="/creators" element={<ProtectedRoute component={Creators} />} />
        <Route path="/monthly-listeners" element={<ProtectedRoute component={MonthlyListeners} />} />
        <Route path="/content-created" element={<ProtectedRoute component={ContentCreated} />} />
        <Route
          path="/total-monthly-listens"
          element={<ProtectedRoute component={SochcastListensMonthly} />}
        />
        <Route path="/total-signups" element={<ProtectedRoute component={TotalSignups} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
