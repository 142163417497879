import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Container } from "react-bootstrap";
// import Select from "react-select";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import apiInstance from "../../api";
import drilldown from "highcharts/modules/drilldown.js";

drilldown(Highcharts);

export const ContentCreated = () => {
  const [value, onChange] = useState([
    moment().subtract(2, "months").date(1).toDate(),
    moment().toDate(),
  ]);
  const [apiResponse, setApiResponse] = useState([]);
  // const [filterDateNumber, setFilterDateNumber] = useState(14);

  // const handleChange = (selectedOption) => {
  //   setFilterDateNumber(selectedOption.value);
  // };

  var finalArray = apiResponse.map(function (obj) {
    return moment(obj.month).format("MMMM yy");
  });

  var finalArrayCount = apiResponse.map(function (obj) {
    return {
      y: Math.round(obj.total_duration / 60),
      drilldown: true,
      name: moment(obj.month).format("MMMM yy"),
    };
  });

  useEffect(() => {
    onCalendarClosed();
  }, []);

  const onCalendarClosed = () => {
    apiInstance
      .get(
        `/content-created-monthly?start_date=${moment(value[0]).format(
          "YYYY-MM-DD"
        )}&end_date=${moment(value[1]).format("YYYY-MM-DD")}`
      )
      .then((res) => {
        if (res.status === 200) {
          setApiResponse(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDrillSeries = useCallback(async (category) => {
    const response = await apiInstance
      .get(
        `/content-created-monthly-daily?month=${moment(category)
          .add(1, "month")
          .month()}&year=${moment(category).format("YYYY")}`
      )
      .then((res) => {
        console.log("res: ", res);

        return res.data.map((dailyData) => [
          moment(dailyData.date).format("DD-MMMM"),
          Math.round((dailyData.total_duration)/60),
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log("response: ", response);
    return response;
  }, []);

  const optionsChart = {
    chart: {
      type: "bar",
      events: {
        drilldown: async function (e) {
          console.log("drill initiated");
          var chart = this;
          const series = await getDrillSeries(e.point.name);
          console.log("series: ", series);
          chart.addSeriesAsDrilldown(e.point, { name: "Date", data: series });
          chart.applyDrilldown();
          console.log("chart: ", chart);
        },
      },
    },
    style: {
      fontFamily: "Poppins",
      color: "#566777",
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "category",
      title: {
        style: {
          fontSize: "12",
          fontFamily:
            "Lucida Grande, Lucida Sans Unicode, Arial, Helvetica, sans-serif",

          color: "rgb(51, 51, 51)",
          fontWeight: "bold",
        },
      },
    },
    yAxis: {
      title: {
        text:"",
        style: {
          fontSize: "14",
          fontFamily: "Poppins",
          color: "#566777",
          fontWeight: "600",
        },
      },
    },
    tooltip: {
      valueSuffix: "Minutes",
      backgroundColor: "#FFFFFF",
      borderRadius: "15",
      borderColor: "#FFFFFF",
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Content Created",
        data: finalArrayCount,
      },
    ],
    drilldown: {
      activeAxisLabelStyle: {
        textDecoration: "none",
        fontStyle:
          "Lucida Grande, Lucida Sans Unicode, Arial, Helvetica, sans-serif",
        color: "rgb(51, 51, 51)",
        fontWeight: "bold",
        fontSize: "12",
      },
      activeDataLabelStyle: {
        textDecoration: "none",
        fontStyle:
          "Lucida Grande, Lucida Sans Unicode, Arial, Helvetica, sans-serif",
        color: "rgb(51, 51, 51)",
        fontWeight: "bold",
        fontSize: "12",
      },
      series: [],
    }
  };
  console.log("finalArrayCount: ", finalArrayCount);

  // const options = [
  //   { value: "14", label: "14 Days" },
  //   { value: "28", label: "28 Days" },
  //   { value: "42", label: "42 Days" },
  //   { value: "56", label: "56 Days" },
  // ];

  return (
    <>
      <Container>
        <div className="my-5">
          <h3 className="text-capitalize">Total Content Created</h3>
          <Row>
            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
              <p className="time-rang">
                Select date range to display analytics (Displayed data is
                monthly based).
              </p>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              className="drop-filter-react"
            >
              {/* <Select
                defaultValue={{ label: "14 Days", value: 14 }}
                onChange={handleChange}
                options={options}
                isSearchable={false}
                isRtl={false}
              /> */}
              <DateRangePicker
                onChange={onChange}
                value={value}
                rangeDivider="to"
                required={true}
                yearPlaceholder="yyyy"
                monthPlaceholder="mm"
                dayPlaceholder="dd"
                maxDate={new Date()}
                onCalendarClose={onCalendarClosed}
                format="y-MM-dd"
              />
            </Col>
          </Row>
          <Row>
            {/* <Col xs={12} sm={12} md={8} lg={8} xl={8}>
              <p className="time-rang submto">
                Monthly Listeners from{" "}
                {filterDateNumber ? filterDateNumber : 0} days.
              </p>
            </Col> */}
            {/* <Col xs={12} sm={12} md={12} lg={12} xl={12}></Col> */}
            <Col>
              <div className="mt-3">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={optionsChart}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};
