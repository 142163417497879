import React, { useState, useEffect } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { TopNavigation } from "../../components/sidebar/TopNavigation";
import { TotalShows } from "../reports/TotalShows";
import moment from "moment";
import apiInstance from "../../api";
import Select from "react-select";

export const Show = () => {
  const [selectDateRange, setSelectDateRange] = useState(500);
  const [showData, setShowData] = useState(null);

  const showList = () => {
    apiInstance
      .get(`/top-shows-daterange?days=${selectDateRange}`)
      .then((res) => {
        if (res.status === 200) {
          setShowData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    showList();
  }, [selectDateRange]);

  const options = [
    { value: 500, label: "All time" },
    { value: 7, label: "7 Days" },
    { value: 15, label: "15 Days" },
    { value: 30, label: "30 Days" },
    { value: 45, label: "45 Days" },
    { value: 60, label: "60 Days" },
    { value: 90, label: "90 Days" },
    { value: 120, label: "120 Days" },
    { value: 180, label: "180 Days" },
    { value: 365, label: "1 Year" },
  ];

  return (
    <>
      <TopNavigation />
      <Container>
        <div className="mt-4 mb-1">
          <h3 className="text-capitalize">top shows</h3>
          <Row className="my-2">
            <Col xs={12} sm={12} md={4} lg={4}>
              <Select
                defaultValue={{ label: "All Time", value: 500 }}
                onChange={(e) => setSelectDateRange(e.value)}
                options={options}
                isSearchable={false}
                isRtl={false}
              />
            </Col>
          </Row>
          <Row>
            {showData?.map((e) => (
              <Col className="mb-3 d-flex justify-content-center" xs={6} md={4} lg={2}>
                <Card className="">
                  <Card.Body>
                    <Row>
                      <Col>
                      <img 
                      src={e.show_image}
                      alt="Sochcast Top 5"
                      className="img-fluid show-image-top-shows"
                      />
                      </Col>
                    </Row>
                  </Card.Body>
                  </Card>
                </Col>
            ))}
          </Row>

        </div>

        <div className="">
          <TotalShows 
          />
        </div>
      </Container>
    </>
  );
};
