import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
import ReactToPrint from "react-to-print";
import { HomePageCards } from "./HomePageCards";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import apiInstance from "../../api";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";

export const HomeReportsFilter = (props) => {
  const [filterDateNumber, setFilterDateNumber] = useState(15);
  // const [value, onChange] = useState([new Date(), new Date()]);
  // const startValue: Date = new Date(
  //   new Date().getFullYear(),
  //   new Date().getMonth()
  // );
  // const endValue: Date = new Date(
  //   new Date().getFullYear(),
  //   new Date().getMonth()
  // );

  const handleReportsPDF = () => {};

  const onCalendarClosed = () => {
    // apiInstance
    //   .get(
    //     `/content-created-monthly?start_date=${moment(value[0]).format(
    //       "YYYY-MM-DD"
    //     )}&end_date=${moment(value[1]).format("YYYY-MM-DD")}`
    //   )
    //   .then((res) => {
    //     if (res.status === 200) {
    //       setApiResponse(res.data);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  // Get Today Date
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();

  // Get first Date of current month
  var my_date = new Date();
  var first_date = new Date(my_date.getFullYear(), my_date.getMonth(), 2);
  var first_day_current_month = first_date.toISOString().split("T")[0];

  const handleChange = (event) => {
    console.log('event: ', event);
    props.setSelectedDateRange(event.value);
  };

  const options = [
    { value: null, label: "All time"},
    { value: 7, label: "7 Days" },
    { value: 15, label: "15 Days" },
    { value: 30, label: "30 Days" },
    { value: 45, label: "45 Days" },
    { value: 60, label: "60 Days" },
    { value: 90, label: "90 Days" },
    { value: 120, label: "120 Days" },
    { value: 180, label: "180 Days" },
    { value: 365, label: "365 Days" }
  ];

  return (
    <Container>
      <div className="my-4">
        <Row>
          <Col xs={12} sm={12} lg={4} md={4}>
            <Select
              defaultValue={{ label: "All time", value: null }}
              onChange={handleChange}
              options={options}
              isSearchable={false}
              isRtl={false}
            />
            {/* <DateRangePicker
              // defaultValue={start_date=${first_day_current_month}toend_date=${today}}
              onChange={e=>{
                console.log('e: ', e);
                
                props.setSelectedDateRange(e)}}
              value={props.selectedDateRange?.length ?props.selectedDateRange : null }
              rangeDivider="to"
              required={true}
              yearPlaceholder="yyyy"
              monthPlaceholder="mm"
              dayPlaceholder="dd"
              autoFocus={false}
              maxDate={new Date()}
              format="yyyy-MM-dd"
              
           
            /> */}
          </Col>
          {/* <Col xs={12} sm={12} lg={4} md={4}>
            <p className="text-center">
            {props.selectedDateRange ? " " : "Showing All Time Data"}
            </p>
          </Col> */}
          {/* <Col xs={12} sm={12} lg={4} md={4}>
            <h4 style={{ float: "right" }}>
              <Button variant="secondary" onClick={handleReportsPDF}>
                Download PDF
              </Button>
            </h4>
          </Col> */}
        </Row>
      </div>
    </Container>
  );
};
