import React from 'react'
import PieChart from "highcharts-react-official";
import Highcharts from "highcharts/highstock";

export const Listens = () => {
    const options = {
        chart: {
          type: "pie"
        },
        style: {
            fontFamily: "Lucida Grande, Lucida Sans Unicode, Arial, Helvetica, sans-serif",
            color: "#566777"
          },
          title: {
            text: "14,48,200 Total Listens",
          }, tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
          },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          }
        },credits: {
          enabled: false
      },
      legend: {
        width: 120 + "%",
        align: 'left',
        verticalAlign: 'bottom',
        
        labelFormatter: function () {
            return this.name +": " + this.y;
        }
    },
        series: [
          {
            name: "",
            color: "#006600",
            lineWidth: 1,
            marker: {
              enabled: false,
              symbol: "circle",
              radius: 3,
              states: {
                hover: {
                  enabled: true,
                  lineWidth: 1
                }
              }
            },
            data: [
              {
                y:348000,
                name:"Gaana",
                color:"orange"
              },
              {
                y: 1032249,
                name:"Sochcast",
                color:"#4CBB17"
              },
              {
                y: 60000,
                name:"Spotify",
                color:"	#5D3FD3"
              },
              {
                y:3997,
                name:"Apple",
                color:"#FFD700"
              },
              {
                y:2754,
                name:"Jio Saavn",
                color:"magenta"
              },
              {
                y:1200,
                name:"Google",
                color:"blue"
              }
            ]
          }
        ]
      };
      
  return (
      <div className='' style={{paddingBottom:"100px"}}>
          <PieChart className="" highcharts={Highcharts} options={options} allowSliceExplosion="true" />
      </div>
  )
}
